@use 'sass:color';

$shareright-primary: #FF671D;
$shareright-primary-light: color.adjust($shareright-primary, $lightness: 15);
$shareright-secondary: #525252;
$gray: #434343;
$red: #941100;
$light-red: #ec3720;
$light-gray: #EBEBEB;


$font-family: 'Poppins', 'Helvetica Neue', sans-serif;
$font-size-small: 0.9rem;
$font-size-large: 1.8rem;


$link-color: $shareright-secondary;
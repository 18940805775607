.terms {
  line-height: 2rem;
}

.terms-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    padding-left: 4rem;
    margin-bottom: 10px;
  }
}
@use "../../styles/variables" as v;

.footer {
  border-top: 2px solid v.$shareright-primary;
  margin-top: 50px;

  .footerInner {
    //width: 1320px;
    //max-width: 100%;
    margin: 0 auto;

    padding: 20px 0;
    background: white;
    display: flex;
    height: auto;
    flex-direction: row;
    justify-content: space-between;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    div {
      width: 15%;
    }
  }

  .CLALogo {
    img {
      display: block;
      width: 100%;
    }
  }
}

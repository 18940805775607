.details_table {
  td {
    padding: 10px 10px 10px 0;

    &:first-child {
      font-weight: bold;
    }
  }
}

.action_buttons {
  display: flex;
  gap: 50px;
}
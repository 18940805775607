@use '../../styles/variables' as v;

.record-thumbnail-container {
  width: 120px;
  height: 100px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);

  .empty-thumbnail {
    background: v.$light-gray;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.wide {
    width: 100%;
    height: auto;
    max-height: 500px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .faded-logo {
    filter: brightness(0%);
    opacity: 0.05;
    width: 60%;
    height: auto;
    object-fit: initial;
  }
}
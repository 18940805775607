@use '../../styles/variables' as v;

.navbar {
  padding-left: 6rem;
  padding-right: 6rem;
}

.navbar {
  background: white;
  border-bottom: 2px solid v.$shareright-primary;
}

.nav-link,
.navbar-brand {
  color: v.$gray;
}

.logo {
  height: 40px;
}

.nav-item {
  display: flex;
  align-items: center;
}

.navbar-inner {
  display: flex;
  width: 100%;
}
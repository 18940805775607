.skeleton {
  background: #ddd;
  position: relative;
  overflow: hidden;

  @keyframes shine {
    100% {
      transform: translateX(100%);
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
                    90deg,
                    rgba(#fff, 0) 0,
                    rgba(#fff, 0.2) 20%,
                    rgba(#fff, 0.5) 60%,
                    rgba(#fff, 0) 0
    );
    animation: shine 2s infinite;
    content: '';
  }
}
@use '../../styles/variables' as v;

.record-view {
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-bottom: 40px;
}

.record-entry {
  padding: 20px 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  .record-entry-info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .details {
      font-size: 0.85rem;
    }
  }
}

.search-container {
  display: flex;
  justify-content: flex-end;

  .search-input {
    border: 1px solid v.$gray;
    padding: 6px 10px;
    border-radius: 0;
    font-family: inherit;
    font-size: inherit;
    min-width: 300px;
  }
}

.action-buttons {
  display: flex;
  gap: 5px;
}

.paginator {
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    font-size: 0.85rem;

    li {
      .fill {
        padding: 6px 10px;
        margin: 10px 0;
        background: v.$shareright-primary-light;
        border-radius: 50px;
        color: white;
        letter-spacing: 0.5px;
        user-select: none;
      }

      button {
        padding: 6px 10px;

        &.active {
          border: 1px solid v.$shareright-primary;
          background: white;
          color: v.$shareright-primary;
        }
      }
    }
  }
}
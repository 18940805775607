@use '../../styles/variables' as v;
@use 'sass:color';

$icon-offset: 1rem;

.password_field_container {
  position: relative;
}

.input {
  padding-right: calc(1.5rem + $icon-offset);
}

.icon {
  cursor: pointer;
  color: color.adjust(v.$gray, $lightness: 30);
  position: absolute;
  top: 50%;
  right: $icon-offset;
  transform: translateY(-50%);
  width: 20px;

  &:hover {
    color: v.$gray;
  }
}
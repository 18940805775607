@use '../../styles/variables' as v;

.contentContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.contentBrands {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  column-gap: 3rem;
  align-items: center;
  justify-content: space-evenly;
  margin: 30px 0;

  img {
    height: 3rem;
    width: auto;
  }
}

.browserStores {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: right;
  justify-content: center;

  .chrome-store {
    max-width: 258px;
  }

  .edge-store {
    width: 245px;
  }

  .firefox-store {
    max-width: 245px;
    background-color: #20123a;
    padding: 10px;
  }
}
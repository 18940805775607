.container {
  position: relative;
  display: inline-block;
}

.input {
  min-width: 300px;
}

.suggestions_container {
  border: 1px solid #ccc;
  background: white;
  position: fixed;
  box-shadow: 1px 2px 6px -1px rgba(0, 0, 0, 0.3);
}

.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.suggestion {
  background: none;
  padding: 4px;
  color: black;
  border: none;
  border-radius: 0;
  margin: 0;
  display: block;
  text-align: left;
  width: 100%;

  &:hover, &.selected {
    background: #2d75e6;
    color: white;
  }
}
@use '../../styles/variables' as v;;
@use 'sass:color';

.email-card {
  background: v.$light-gray;
  border: 1px solid #bbb;
  padding: 4px 6px;
  font-size: 0.9rem;
  display: inline-block;

  .card-inner {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 8px;
    align-items: center;
    height: 100%;
  }

  .picture {
    $size: 30px;
    height: $size;
    width: $size;
    border-radius: 50%;
    background: #4492d0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    user-select: none;
  }

  .remove {
    color: v.$gray;
    border: none;
    padding: 0;
    margin: 0;
    display: block;
    background: none;
    font-size: 1.2rem;

    &:hover {
      color: color.adjust(v.$gray, $lightness: 20);
    }
  }

  .card-warning {
    color: v.$light-red;
  }
}
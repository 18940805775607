@use '../../styles/variables' as v;

.share-view {
  display: grid;
  grid-template-columns: 2fr 4fr;
  gap: 40px;
  margin-bottom: 40px;

  .share-info {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 100%;
    font-size: v.$font-size-small;

    li {
      margin-bottom: 10px;
    }

    .empty-thumbnail {
      min-height: 200px;
    }
  }

  .share-section {
    margin-bottom: 10px;
  }

  .emails-add-btn {
    cursor: pointer;
    margin-left: 10px;
    color: v.$gray;

    &:hover {
      opacity: 0.5;
    }
  }

  header {
    font-weight: 600;
  }

  .share-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 50px;
    align-items: center;
  }

  .email-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .email-input {
    width:  500px;
    max-width: 100%;
  }

  .response-box {
    padding: 15px;
    margin: 10px 0;
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    color: black;
    width: 100%;
    text-align: center;

    &.response-error, &.response-success, &.response-warning {
      &:before {
        font-weight: bold;
      }
    }
    &.response-error {
      background: #d36565;
      border-color: #942b2b;

      color: white;
      &:before {
        content: "Error! ";
      }
    }
    &.response-success {
      background: #D1E8B2;
      border-color: #588C10;
      &:before {
        content: "Success! ";
      }
    }
    &.response-warning {
      background: #EEB78F;
      border-color: #8C4612;
      &:before {
        content: "Warning! ";
      }
    }
  }
}

.copy-btn {
  color: v.$shareright-primary;

  &:hover {
    color: v.$shareright-primary-light;
  }
}

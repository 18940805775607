@use '../../styles/variables' as v;

.view-copy-container {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100vw;
  height: 100vh;

  .banner {
    background: white;
    border-bottom: 2px solid v.$shareright-primary;
    padding: 10px 50px;
    color: #434343;
    font-family: "IBM Plex Sans", Helvetica, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;

    .logo {
      margin-right: 40px;
    }

    .link-button {
      padding: 0;
      margin: 0;
      background: none;
      border-radius: 0;
      color: v.$shareright-primary;
      text-decoration: underline;

      &:hover {
        color: v.$shareright-primary-light;
      }
    }
  }

  .copy-iframe {
    width: 100%;
    height: 100%;
  }
}
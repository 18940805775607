@use 'styles/variables' as v;
@use 'sass:color';

:root {
  --bs-body-font-family: #{v.$font-family};
  --bs-body-color: #{v.$gray};
  --bs-link-color: #{v.$shareright-primary};
  --bs-link-hover-color: #{v.$shareright-primary-light};
}

.container {
  --bs-gutter-x: 0;
}

.btn-primary {
  // buttons
  --bs-btn-bg: #{v.$shareright-primary};
  --bs-btn-border-color: #{v.$shareright-primary};
  --bs-btn-hover-border-color: #{v.$shareright-primary};
  --bs-btn-hover-bg: #{v.$shareright-primary-light};
  --bs-btn-color: white;
}

.btn:disabled {
  --bs-btn-disabled-bg: #{v.$shareright-primary};
  --bs-btn-disabled-border-color: #{v.$shareright-primary};
}

.btn .spinner-border {
  margin-right: 5px;
}

.link-btn {
  color: v.$shareright-primary;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:hover {
    color: v.$shareright-primary-light;
  }
}

.sharebox-btn {
  padding: 6px 16px;
  border-radius: 50px;
  background: v.$shareright-primary;
  color: white;
  font-weight: 600;
  font-family: inherit;
  min-width: 120px;
  display: inline-block;
  text-align: center;

  &:hover {
    color: white;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.icon-btn {
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 4px;
  color: v.$gray;

  &:hover {
    color: color.adjust(v.$gray, $lightness: 30);
  }
}

h1, h2, h3, h4 {
  font-weight: 600;
}

h3 {
  margin-bottom: 1.25rem;
}

a {
  text-decoration: none;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  width: 560px;
  max-width: 100%;
  padding: 15px 40px;
  text-align: center;

  h1 {
    margin-bottom: 1.2rem;
  }

  .card-inner {
    text-align: left;
    width: 70%;
    margin: 0 auto;
  }
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error {
  color: v.$red;
}

.cla-btn {
  width: 100%;
}

.cla-card {
  background-color: white;
  border: none;
  box-shadow: none;
}
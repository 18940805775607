@use '../../styles/variables' as v;
@use 'sass:color';

.overlay {
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
}

.container {
  background: white;
  padding: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.action_buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.emails {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.email {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.email_label {
  margin-top: 0;
  display: flex;
  gap: 5px;
}

.cancel_icon {
  color: v.$gray;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  cursor: pointer;

  &:hover {
    color: color.adjust(v.$gray, $lightness: 30);
  }
}
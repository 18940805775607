.footerContainer {
  padding: 10px 5% 0;
  border-top: solid #ff671d;
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.homeBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.homeContainer {
  line-height: 30px;
}

.homeBrands {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  column-gap: 3rem;
  align-items: center;
  justify-content: center;
  padding: 0 28px;

  img {
    height: 4rem;
    width: auto;
  }
}

.descriptionText {
  padding: 10px;
}

.homeBody header {
  font-weight: bold;
  font-size: large;
}


.alert {
  margin-top: 8px;
}

button, .button {
  padding: 12px 18px;
  border-radius: 50px;
  background-color: #ff671d;
  color: white;
  border: none;
  margin: 10px 0;
  line-height: inherit;
  font-size: inherit;
  display: inline-block;

  &:hover {
    color: white;
  }
}

.home-button {
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 32px;
}

.descriptionImage {
  width: 60%;
}

.descriptionImg {
  width: 100%;
}